@font-face {
  font-family: "Avenir Next";
  font-weight: 300;
  src: local("Avenir Next"), url(./Fonts/AvenirNext-300.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 400;
  src: local("Avenir Next"), url(./Fonts/AvenirNext-400.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 500;
  src: local("Avenir Next"), url(./Fonts/AvenirNext-500.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 600;
  src: local("Avenir Next"), url(./Fonts/AvenirNext-600.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 700;
  src: local("Avenir Next"), url(./Fonts/AvenirNext-700.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 900;
  src: local("Avenir Next"), url(./Fonts/AvenirNext-900.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Leaflet Container */
.leaflet-container {
  height: 100%;
}

/* Shimmer basic*/
.shimmer {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #999;
  background: linear-gradient(
    105deg,
    #f6f6f6 10%,
    #f0f0f0 15%,
    #f6f6f6 25%,
    #f0f0f0 33%,
    #f6f6f6 50%
  );
  background-size: 300% 100%;
  z-index: 1;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -300% 0;
  }
  100% {
    background-position: 300% 0;
  }
}

a {
  text-decoration: none;
}

.MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
  margin-top: 0px !important;
}

.agora_video_player {
  object-fit: contain !important;
}

.dropdown-menu {
  margin-top: 30px;
}

.dropdown-menu .MuiMenu-paper {
  overflow-y: unset;
  overflow-x: unset;
}

.dropdown-menu .MuiMenu-paper:nth-child(3) {
  border-radius: 20px;
}

.dropdown-menu ul {
  padding: 0;
}

.dropdown-menu ul li {
  overflow: hidden;
}

.MuiPhoneNumber-flagButton {
  display: block; /* for safari */
  width: 10px;
}

a {
  color: #007AFF;
}


/* For chat screen */
.str-chat__avatar {
  cursor: pointer;
}

/* For Neighbourhood directory */
.nh-dir-list {
  height: 380px !important;

  /* Hide scrollbars in IE, Edge, Firefox */
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

/* Hide scrollbars in Chrome, Safari, Opera */
.nh-dir-list::-webkit-scrollbar {
  display: none;
}

.StripeElement {
  border: 2px solid #cdcdcd;
  padding: 11px 24px;
  border-radius: 5px;
}

.StripeElement--invalid {
  border: 1px solid red;
}